
export const getName = (state) => state.name

export const getStatusRequest = (state) => state.status_request_login

// export const getFullname = (state) => state.fullname

// export const getLastName = (state) => state.lastname

// export const getDateBirth = (state) => state.datebirth

// export const getEmail = (state) => state.email

// export const getStatusUser = (state) => state.statususer

