<template>
  <Perfil />
</template>

<script>
import Perfil from "@/components/auth/Profile/index.vue";

export default {
  components: {
    Perfil,
  },
};
</script>
